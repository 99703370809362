.modal-curtain{
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1300;
    background-color: rgba(0, 0, 0, 0.50);
}

.oche-modal{
    width: 95%;
    height: 95%;
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
    overflow-y: auto;
    background-color: var(--card);
    padding: 1rem;
    align-items: flex-end;
}

.oche-modal i{
    font-size: x-larger;
    text-align: end;
    cursor: pointer;
}