.oche-canevas {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    column-gap: 1rem;
    overflow: hidden;
}

.caneva-builder {
    width: 50%;
    height: 100%;
    overflow: auto;
    padding: 1rem;
}

.single-section {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
}

.s-action-main {
    display: flex;
    flex-direction: column;
    width: 100%;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    margin-bottom: 1rem;
}

.s-action-part {
    width: 100%;
    display: flex;
    padding: 1rem;
    align-items: center;
    justify-content: space-between;
}

.caneva-builder .oche-inputs-item {
    margin: 1.5rem 0rem;
    align-items: flex-start;
    border-bottom: .1rem solid rgb(221, 221, 221);
    padding-bottom: 0;
}

.s-action-data {
    width: 100%;
    height: fit-content;
    padding: 1rem;
    background-color: rgb(221, 221, 221);
}

.indicator-form{
    column-gap: .5rem;
    display: flex;
    flex-direction: column;
}

.single-section .options-reg{
    margin-top: .5rem;
}

.opts-single-section{
    background-color: rgb(175, 175, 175) !important;
}