.oche-form {
    width: 100%;
    display: flex;
    overflow: hidden;
    height: 100%;
}

.oche-inputs {
    width: 50%;
    height: 100%;
    display: flex;
    padding: 1.5rem;
    flex-direction: column;
    row-gap: .5rem;
    overflow-y: auto;
}

.oche-inputs-title {
    width: 100%;
    height: fit-content;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.oche-inputs-title button {
    height: fit-content;
    padding: .5rem 1.5rem;
}

.single-oche-input {
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: rgb(198, 198, 198);
    padding: 0 1rem;
    border: .1rem solid rgb(224, 224, 224);
    row-gap: .5rem;
    padding-bottom: 1rem;
}

.oche-inputs-item {
    display: flex;
    width: 100%;
    align-items: flex-start;
    column-gap: 1rem;
    border-bottom: .1rem solid gray;
    padding-bottom: 1rem;
}


.options-inputs-row {
    display: flex;
    width: 100%;
    align-items: flex-start;
    column-gap: 1rem;
    margin-top: 1rem;
    border-top: .1rem solid gray;
    padding-top: .5rem;
}

.options-inputs-row .opts-single-form {
    width: 100%;
    padding: 1rem;
    background-color: gray;
}

.prov-modal {
    background-color: rgb(227, 227, 227) !important;
}

.munis-div {
    width: 100%;
}

.for-inputs {
    width: 100%;
    margin-top: .5rem;
}
.for-inputs .oche-inputs-item{
    margin-top: 1rem;
}

.for-inputs button {
    font-size: smaller;
    padding: .5rem 1rem;
}

.for-inputs .oche-inputs-title .p-medium {
    font-size: medium;
}


.for-inputs .divider{
    display: none;
}