.oche-home{
    width: 100vw;
    height: 100vh;
    background-color: var(--bgColor);
    display: flex;
    flex-direction: column;
    justify-content: center;
    row-gap: 1rem;
}

.oche-home-app{
    width: 100%;
    display: flex;
    justify-content: space-between;
    column-gap: 1rem;
    padding: .5rem 1.5rem;
    background-color: var(--card);
}

.icon-button{
    font-size: xx-large;
    color: var(--primaryColor);
    cursor: pointer;
}

.oche-home-actions{
    display: flex;
    width: 100%;
    column-gap: 1rem;
    padding: 0rem 20rem;
}

.divider{
    width: 100%;
    padding: .1rem;
    border-bottom: .1rem solid rgb(233, 233, 233);
}

.oche-home-single-home{
    background-color: var(--card);
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 3rem 1rem;
    cursor: pointer;
    border-radius: 0rem;
}

.oche-home-single-home i{
    font-size: x-large;
    margin: .2rem;
}

.oche-home-save-actions{
    width: 100%;
    padding: 0rem 20rem;
    display: flex;
    flex-direction: row;
    column-gap: 2rem;
    align-items: center;
    justify-content: flex-start;
}

.oche-home-save-actions button{
    background-color: var(--bgColor);
    color: var(--primaryColor);
    padding: 0;
    margin: 0;
}
