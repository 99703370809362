
.oche-languages{
    width: 100%;
    display: flex;
    height: 100%;
    column-gap: 1rem;
    overflow: hidden;
}

.oche-languages-dic, .json-displayer{
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
    overflow-y: auto;
    overflow-x: hidden;
    background-color: var(--bgColor);
    padding: 1rem;
}

.new-key-form{
    background-color:var(--bgColor);
    padding: 1rem;
}

.new-key-form button{
    width: 100%;
    padding: .5rem 1rem;
}

.button-loading{
    background-color: gray !important;
}