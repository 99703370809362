.oche-main{
    width: 100vw;
    height: 100vh;
    display: flex;
    background-color: var(--bgColor);
    padding: 1rem;
}

.oche-main-left, .oche-main-right{
    width: 50%;
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
    padding: 1rem;
    height: 100%;
    justify-content: center;
    padding: 1rem 4rem;
}



.oche-main-actions{
    display: flex;
    width: 100%;
    column-gap: 1rem;
}

.oche-main-right img{
    padding: 3rem !important;
    height: 100%;
    width: 100%;
    object-fit: contain;
}
