

.oche-registers{
    width: 100%;
    height: 100%;
    display: flex;
    overflow: hidden;
    column-gap: 1rem;
}


.oche-registers-list, .oche-registers-form{
    width: 50%;
    height: 100%;
    overflow-y: auto;
    background: var(--bgColor);
    padding: 1.5rem;
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
}

.oche-registers-form button{
    width: 100%;
    height: fit-content;
    padding: .5rem;
}

.single-register{
    width: 100%;
    background-color: var(--card);
    padding: 1rem;
    display: flex;
    flex-direction: column;
    cursor: pointer;
}

.bg-green{
    background-color: green;
}

.bg-orange{
    background-color: orange;
}

#p-bg{
    width: fit-content;
    color: white;
    padding: .5rem;
    font-size: small;
    margin-bottom: .5rem;
}

.single-register .flex-row{
    align-items: center;
    justify-content: space-between;
}